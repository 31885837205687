import classnames from "classnames";
import { useIntl } from "react-intl";

import { SETTINGS_PATH, TRANSACTION_PATH } from "util/routes";
import { SCHEDULED_PATH } from "common/closing_agenda/path";
import { GET_STARTED_PATH, TEAM_PATH } from "common/proof_frame/path";
import Link from "common/core/link";
import Icon from "common/core/icon";
import { isMobileDevice } from "util/support";
import { useFeatureFlag } from "common/feature_gating";

import {
  meetingsLabel,
  orgSettingsLabel,
  sendAndManageLabel,
  usersLabel,
  useIsPathActive,
  getStartedLabel,
  nava11yLabel,
} from "../common";
import Styles from "./index.module.scss";

export function MobileDeviceNav() {
  const intl = useIntl();
  const transactionPathActive = useIsPathActive("/transaction");
  const rootPathActive = useIsPathActive("/", true);

  const sendAndManage = {
    to: TRANSACTION_PATH,
    icon: <Icon name="dashboard-filled" />,
    label: sendAndManageLabel,
    isActive: transactionPathActive || rootPathActive,
  };

  const meetings = {
    to: SCHEDULED_PATH,
    icon: <Icon name="calendar-filled" />,
    label: meetingsLabel,
    isActive: useIsPathActive(SCHEDULED_PATH),
  };

  const organizationSettings = {
    to: SETTINGS_PATH,
    icon: <Icon name="settings-filled" />,
    label: orgSettingsLabel,
    isActive: useIsPathActive(SETTINGS_PATH),
  };

  const teamMembers = {
    to: TEAM_PATH,
    icon: <Icon name="employees-filled" />,
    label: usersLabel,
    isActive: useIsPathActive(TEAM_PATH),
  };

  const getStarted = {
    to: GET_STARTED_PATH,
    icon: <Icon name="get-started-filled" />,
    label: getStartedLabel,
    isActive: useIsPathActive(GET_STARTED_PATH),
  };

  const navLinks = [sendAndManage, meetings, organizationSettings, teamMembers, getStarted];

  return (
    <nav
      aria-label={intl.formatMessage(nava11yLabel)}
      className={Styles.nav}
      data-automation-id="mobile-nav"
    >
      <ul className={Styles.navLinks}>
        {navLinks.map((link) => (
          <li key={link.to}>
            <Link
              white
              aria-label={intl.formatMessage(link.label)}
              className={classnames(Styles.navLink, link.isActive && Styles.navLinkActive)}
              to={link.to}
            >
              {link.icon}
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  );
}

export function useMobileDeviceNav() {
  const proMobileOnboardingEnabled = useFeatureFlag("pro-mobile-onboarding");
  return isMobileDevice() && proMobileOnboardingEnabled;
}
